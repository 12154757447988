import React, { Fragment } from "react";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import NoPic from '../images/nopic.png';
import { FaArrowLeft } from 'react-icons/fa';
import Seo from "../components/seo";

export default function Category({ data }) {

  const page = data.allWpPortfolioCategory

  return (
    <Layout>
      <Seo 
        title={page.nodes[0].wpParent.node.name}
        description={page.nodes[0].wpParent.node.description}
        image={page.nodes[0].wpParent.node.extra.thumbnail.mediaItemUrl}
      />
      <Fragment>
        <div className="row ptb--30">
          <div style={{width:'auto'}}>
            <button style={{backgroundColor:'transparent',border:'none',textAlign:'left',paddingBottom:'10px'}} onClick={() => navigate(-1)}>
              <FaArrowLeft/>
            </button>
          </div>
          <h1 style={{marginBottom:'0',width:'auto',position:'absolute',left:'50%',transform:'translateX(-50%)'}}>
            {page.nodes[0].wpParent.node.name}
          </h1>
          <p className="pt--20">{page.nodes[0].wpParent.node.description}</p>
        </div>
        <div className="pt--20 pb--50 row">
        {page.nodes.map((node, i) => (
          node.portfolioItems.nodes.length > 0 ?
          <Fragment>
            <div style={{textAlign:'center',alignSelf:'flex-end'}} className="col-12 col-md-4 folio" key={i}>
              {node.extra.thumbnail ?
              <Link to={node.slug}>
                <GatsbyImage
                  style={{maxWidth:'300px',alignSelf:'center'}}
                  image={node.extra.thumbnail.localFile.childImageSharp.gatsbyImageData}
                  alt={node.name}
                />
                <h2 className="ptb--30" style={{fontSize:'24px'}}>
                  {node.name}
                </h2>
                </Link>
                :
                <>
                <img 
                  style={{maxWidth:'300px'}}
                  src={NoPic} 
                  alt="nopic"
                />
                <h2 className="ptb--30" style={{fontSize:'24px'}}>
                  {node.name}
                </h2>
                </>
              }
            </div>
            </Fragment>
            : null
        ))}
        </div>
      </Fragment>
    </Layout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    allWpPortfolioCategory(
      filter: {wpParent: {node: {slug: {eq: $slug}}}}
      sort: {fields: extra___order}
      ) {
      nodes {
        name
        slug
        description
        extra {
          order
        }
        wpParent {
          node {
            description
            name
            slug
            extra{
              thumbnail{
                mediaItemUrl
              }
            }
          }
        }
        extra {
          shortDescription
          fullDescription
          height
          width
          thumbnail {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 800,
                  quality: 100,
                  placeholder: DOMINANT_COLOR,
                  formats: AUTO
                  )
              }
            }
          }
        }
        portfolioItems {
          nodes {
            title
            slug
            uri
            content
            extra{
              hide
              gallery{
                mediaItemUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: DOMINANT_COLOR, formats: AUTO)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`